import React from 'react';
import {Grid, TextField} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useState, useEffect} from "react";
import Button from '@mui/material/Button'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {addDoc, collection} from '@firebase/firestore'
import {firebase, storage} from '../../firebase'

const counties = [
    'Alba',
    'Arad',
    'Arges',
    'Bacau',
    'Bihor',
    'Bistrita-Nasaud',
    'Botosani',
    'Braila',
    'Brasov',
    'Buzau',
    'Calarasi',
    'Caras-Severin',
    'Cluj',
    'Constanta',
    'Covasna',
    'Dambovita',
    'Dolj',
    'Galati',
    'Giurgiu',
    'Gorj',
    'Harghita',
    'Hunedoara',
    'Ialomita',
    'Iasi',
    'Ilfov',
    'Maramures',
    'Mehedinti',
    'Mures',
    'Neamt',
    'Olt',
    'Prahova',
    'Salaj',
    'Satu Mare',
    'Sibiu',
    'Suceava',
    'Teleorman',
    'Timis',
    'Tulcea',
    'Valcea',
    'Vaslui',
    'Vrancea'
];


function NewDistributor(props) {

    const [county, setCounty] = useState('Gorj')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [coordinates, setCoordinates] = useState({
        lat: 0,
        lng: 0
    })
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')

    return (
        <Grid container width={'100vw'} direction={'row'} sm={12} spacing={10}>
            <Grid item sm={6}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} >
                    <Grid item sm={12}>
                        <InputLabel >Judet distribuitor</InputLabel>
                        <Select
                            sx={{minWidth: '10vw'}}
                            value={county}
                            label="Categorie"
                            onChange={(event) => {
                                setCounty(event.target.value)
                            }}
                        >
                            {counties.map((county) => {
                                return <MenuItem value={county}> {county} </MenuItem>
                            })}
                        </Select>
                    </Grid>

                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            required
                            label="Nume distribuitor"
                            margin="normal"
                            onChange={(event)=> {setName(event.target.value)}}
                            value={name}
                            variant={'outlined'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            required
                            label="Numar de telefon"
                            margin="normal"
                            onChange={(event)=> {setPhone(event.target.value)}}
                            value={phone}
                            variant={'outlined'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            required
                            label="Email"
                            margin="normal"
                            onChange={(event)=> {setEmail(event.target.value)}}
                            value={email}
                            variant={'outlined'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            required
                            label="Adresa distribuitorului"
                            margin="normal"
                            onChange={(event)=> {setAddress(event.target.value)}}
                            value={address}
                            variant={'outlined'}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Latitudine"
                                    margin="normal"
                                    onChange={(event)=> {setCoordinates({...coordinates, lat: event.target.value})}}
                                    value={coordinates.lat}
                                    variant={'outlined'}
                                />
                            </Grid>

                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Longitudine"
                                    margin="normal"
                                    onChange={(event)=> {setCoordinates({...coordinates, lng: event.target.value})}}
                                    value={coordinates.lng}
                                    variant={'outlined'}
                                />
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid item sm={12}>
                    <Button variant={'outlined'} onClick={() => {
                        setTimeout(() => {
                            const data = {
                                name: name,
                                address: address,
                                county: county,
                                coordinates: coordinates,
                                email: email,
                                phone: phone
                            }
                            const distrubutors = collection(firebase, `distributors`)
                            addDoc(distrubutors, data).then(
                                () => {
                                    alert("Succes!")
                                    setCoordinates({
                                        lat: 0,
                                        lng: 0
                                    })
                                    setPhone('')
                                    setName('')
                                    setAddress('')
                                    setEmail('')
                                    setCounty('Gorj')
                                }


                            )
                        }, 500)
                    }}>Adauga distribuitor</Button>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default NewDistributor;