import React, { useState } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Photo from "./Photo";
import {arrayMoveMutable} from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const SortablePhoto = SortableElement(item => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items }) => (
    <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />
));

function DraggableImageGallery({images, setImages}) {

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let array = [...images]
        let temp = array[oldIndex];
        array[oldIndex] = array[newIndex];
        array[newIndex] = temp;
        setImages(array);
    };

    const newImages = []
    images.map(image => {
        newImages.push( {
            src: image,
            width: 0.5,
            height: 0.5
        })
    })
    return (
            <SortableGallery items={newImages} onSortEnd={onSortEnd} axis={"xy"} />
    );
}

export default DraggableImageGallery;