import React, {useState} from 'react';
import {Grid, List, ListItem, ListItemText, TextField} from "@mui/material";
import {useRef} from "react";
import Button from "@mui/material/Button";
import Product from "../produse/Product";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {addDoc, collection} from '@firebase/firestore'
import {firebase, storage} from '../../firebase'
import DraggableImageGallery from "./DraggableImageGallery";

function NewProduct(props) {


    const form = useRef();

    const handleSubmit = (e) => {
       // save to db
    }

    const categories = ['Bolțari de gard', 'Bolțari de zidărie', 'Bolțari de fundație', 'Borduri rezidențiale', "Borduri carosabile", 'Pavele rezidențiale', 'Pavele clasice']


    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const [newSpecification, setNewSpecification] = useState('')
    const [technicalSpecifications, setTechnicalSpecifications] = useState([])

    const [newAdvantage, setNewAdvantage] = useState('')
    const [advantages, setAdvantages] = useState([])

    const [dimensions, setDimensions] = useState({
        length: '',
        width: '',
        height: ''
    })

    const [newColor, setNewColor] = useState('')
    const [colors, setColors] = useState([])

    const [category, setCategory] = useState()

    const [images, setImages] = useState([])


    const handleSave = async () => {

        if (!(name && images !== [] && advantages && dimensions && colors && description && technicalSpecifications && category)) {
            alert("Date insuficiente")
            return;
        }
        const productData = {
            name: name,
            description: description,
            advantages: advantages,
            dimensions: dimensions,
            colors: colors,
            technicalSpecifications: technicalSpecifications,
            category: category,
            images: images,
        }
        let newImages = []

        const saveImages = async () => {
            await Promise.all(
                await images.map(async (image, index) => {
                    const imageName = `${name}_${index}`;
                    const storageRef = ref(storage, `product_images/${imageName}`)
                    const imageFile = await fetch(image).then((r) => r.blob());
                    await uploadBytes(storageRef, imageFile).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then( url => newImages.push(url));
                    })
                })
            )
        }

        await saveImages()

        setTimeout(() => {
            newImages.sort()
            productData.images = newImages;
            const currentProduct = collection(firebase, `products`)
            addDoc(currentProduct, productData).then(
                () => {
                    alert("Succes!")
                    setImages([])
                    setCategory('')
                    setTechnicalSpecifications([])
                    setDimensions({
                        length: '',
                        width: '',
                        height: ''
                    })
                    setAdvantages([])
                    setDescription('')
                    setName('')
                    setColors([])
                }


            )
        }, 500)


        // Create a Firebase Cloud

    };


    return (
        <Grid container width={'100vw'} direction={'row'} sm={12} spacing={10}>
            <Grid item sm={6}>
                <form ref={form} onSubmit={handleSubmit}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} >
                        <Grid item sm={12}>
                            <InputLabel >Categorie produs</InputLabel>
                            <Select
                                sx={{minWidth: '10vw'}}
                                value={category}
                                label="Categorie"
                                onChange={(event) => {
                                    setCategory(event.target.value)
                                }}
                            >
                                {categories.map((category) => {
                                    return <MenuItem value={category}> {category} </MenuItem>
                                })}
                            </Select>
                        </Grid>

                        <Grid item sm={12}>
                            <TextField
                                fullWidth
                                required
                                label="Denumire produs"
                                margin="normal"
                                onChange={(event)=> {setName(event.target.value)}}
                                value={name}
                                variant={'outlined'}
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <TextField
                                fullWidth
                                required
                                label="Descriere produs"
                                margin="normal"
                                onChange={(event)=> {setDescription(event.target.value)}}
                                value={description}
                                variant={'outlined'}
                            />
                        </Grid>
                        <Grid item sm={12}>

                            <Grid container direction={'row'} justifyContent={'center'} alignItems={'flex-start'} spacing={2} >
                                <Grid item sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Specificatie tehnica"
                                        margin="normal"
                                        onChange={(event)=> {setNewSpecification(event.target.value)}}
                                        value={newSpecification}
                                        variant={'outlined'}
                                    />
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => {
                                            let newSpecs = [...technicalSpecifications]
                                            newSpecs.push(newSpecification)
                                            setNewSpecification('')
                                            setTechnicalSpecifications(newSpecs)
                                        }}>Adauga</Button>
                                </Grid>

                                <Grid item sm={6}>
                                    <List sx={{backgroundColor: 'whitesmoke'}}>
                                        {technicalSpecifications.map((spec) => {
                                            return  <ListItem>
                                                <ListItemText>
                                                    {spec}
                                                </ListItemText>
                                            </ListItem>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>

                            <Grid container direction={'row'} justifyContent={'center'} alignItems={'flex-start'} spacing={2} >
                                <Grid item sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Avantaje"
                                        margin="normal"
                                        onChange={(event)=> {setNewAdvantage(event.target.value)}}
                                        value={newAdvantage}
                                        variant={'outlined'}
                                    />
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => {
                                            let newSpecs = [...advantages]
                                            newSpecs.push(newAdvantage)
                                            setNewAdvantage('')
                                            setAdvantages(newSpecs)
                                        }}>Adauga</Button>
                                </Grid>

                                <Grid item sm={6}>
                                    <List sx={{backgroundColor: 'whitesmoke'}}>
                                        {advantages.map((spec) => {
                                            return  <ListItem>
                                                <ListItemText>
                                                    {spec}
                                                </ListItemText>
                                            </ListItem>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>


                            <Grid container direction={'row'} justifyContent={'center'} alignItems={'flex-start'} spacing={2} >
                                <Grid item sm={12}>
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                        <h3>DIMENSIUNI</h3>
                                    </div>
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Lungime"
                                        margin="normal"
                                        onChange={(event)=> {setDimensions({...dimensions, length: event.target.value})}}
                                        value={dimensions.length}
                                        variant={'outlined'}
                                    />
                                </Grid>

                                <Grid item sm={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Latime"
                                        margin="normal"
                                        onChange={(event)=> {setDimensions({...dimensions, width: event.target.value})}}
                                        value={dimensions.width}
                                        variant={'outlined'}
                                    />
                                </Grid>

                                <Grid item sm={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Inaltime"
                                        margin="normal"
                                        onChange={(event)=> {setDimensions({...dimensions, height: event.target.value})}}
                                        value={dimensions.height}
                                        variant={'outlined'}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>

                            <Grid container direction={'row'} justifyContent={'center'} alignItems={'flex-start'} spacing={2} >
                                <Grid item sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Culoare"
                                        margin="normal"
                                        onChange={(event)=> {setNewColor(event.target.value)}}
                                        value={newColor}
                                        variant={'outlined'}
                                    />
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => {
                                            let newSpecs = [...colors]
                                            newSpecs.push(newColor)
                                            setNewColor('')
                                            setColors(newSpecs)
                                        }}>Adauga</Button>
                                </Grid>

                                <Grid item sm={6}>
                                    <List sx={{backgroundColor: 'whitesmoke',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: 0
                                    }}>
                                        {colors.map((spec) => {
                                            return  <ListItem>
                                                <div style={{backgroundColor: `#${spec}`, width: '20px', height: '20px', borderRadius: '50%'}}/>
                                            </ListItem>
                                        })}
                                    </List>
                                </Grid>

                            </Grid>

                        </Grid>
                        <Grid item sm={12} >

                            <h3>Adauga imagini</h3>
                                <input multiple accept="image/*" type="file" onChange={(e) => {
                                    const selectedImages = Array.from(e.target.files);
                                    setImages(selectedImages.map((image) => URL.createObjectURL(image)));
                                }}/>
                        </Grid>


                        <Grid item sm={12}>
                            <Button variant={"outlined"} onClick={handleSave}>Salveaza</Button>
                        </Grid>

                        </Grid>
                </form>
            </Grid>

            <Grid item sm={6}>
                <Box width={'100%'} alignItems={'center'} paddingLeft={'20%'}>

                    <Product name={name} description={description} images={images} dimensions={dimensions}
                              technicalSpecifications={technicalSpecifications} advantages={advantages}
                              colors={colors}/>
                </Box>
            </Grid>

            <Grid item sm={12} >
                {/*{images.map((image) => {*/}
                {/*    return <img src={image}/>*/}
                {/*})}*/}

                <DraggableImageGallery images={images} setImages={setImages}/>

            </Grid>

        </Grid>
    );

}

export default NewProduct;