import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootPage from "./components/RootPage";
import { Navigate } from "react-router-dom";
import Products from "./components/produse/Products";
import Distributors from "./components/distribuitori/Distributors";
import Partners from "./components/parteneri/Partners";
import Contact from "./components/contact/Contact";
import Home from './components/acasa/Home'
import Dashboard from "./components/dashboard/Dashboard";

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootPage />,
        children: [
            {
                path: "",
                element: <Navigate to={'/acasa'}/>
            },
            {
                path: "acasa",
                element: <Home/>
            },
            {
                path: "produse",
                element:  <Products/>
            },

            {
                path: "distribuitori",
                element:  <Distributors/>

            },

            {
                path: "parteneri",
                element:  <Partners/>
            },


            {
                path: "contact",
                element:  <Contact/>
            },

            {
                path: 'admin-dashboard',
                element: <Dashboard/>
            },

            {
                path: '*',
                element:  <Navigate to={"/acasa"}/>
            },


        ],
    },
]);


function App() {
  return (
      <>
          <RouterProvider router={router} />
      </>
  );
}

export default App;
