import React, {useState} from 'react';
import {Card, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Authenticate from "./Authenticate";
import Authenticated from "./Authenticated";
function Dashboard(props) {

    const [auth, setAuth] = useState(false);

    return (
        !auth
            ?
            <Authenticate setAuth={setAuth}/>
            :
            <Authenticated/>
    );
}

export default Dashboard;