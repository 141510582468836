import '../styles.css'
import {useRef, useState} from "react";
import {Card, FormControl, Grid, Paper, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {createTheme, styled} from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from "@mui/material/IconButton";
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';
// import emailjs from '@emailjs/browser'
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {ThemeProvider} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import emailjs from 'emailjs-com'

const CustomButton = styled(Button)(() => ({
    color: '#ff850c',
    borderColor: '#ff850c',
    backgroundColor:  'white',
    '&:hover': {
        backgroundColor:  '#ffab59',
        borderColor: '#ff850c',
    },
}));

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#ff850c'
        },
    },
});



function ContactForm(){

    const desktop = useMediaQuery('(min-width:900px)');


    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [clientType, setClientType] = useState('Cere ofertă')

    const [companyName, setCompanyName] = useState('')
    const [companyPhone, setCompanyPhone] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyMessage, setCompanyMessage] = useState('')

    const [partnerName, setPartnerName] = useState('')
    const [partnerPhone, setPartnerPhone] = useState('')
    const [partnerEmail, setPartnerEmail] = useState('')
    const [partnerAddress, setPartnerAddress] = useState('')
    const [partnerMessage, setPartnerMessage] = useState('')

    const categories = ['Cere ofertă', 'Devino distribuitor', 'Devino partener']

    const form = useRef();
    const formCompany = useRef();
    const formPartner = useRef();

    const handleSubmitClient = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_3f3w8lt', 'template_p9853eb', form.current, 'GQGlc9HEK3erskEee')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const handleSubmitCompany = (e) => {

        e.preventDefault();
        emailjs.sendForm('service_crwognn', 'template_4yj3l8x', formCompany.current, 'bWx4UOK36ADrKkb1n')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });


        setCompanyName('')
        setCompanyPhone('')
        setCompanyAddress('')
        setCompanyEmail('')
        setCompanyMessage('')
    }

    const handleSubmitPartner = (e) => {


        e.preventDefault();
        emailjs.sendForm('service_crwognn', 'template_32yq6li', formPartner.current, 'bWx4UOK36ADrKkb1n')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        setPartnerName('')
        setPartnerPhone('')
        setPartnerEmail()
        setPartnerAddress('')
        setPartnerEmail('')
        setPartnerMessage('')
    }

    const handleFunction = [handleSubmitClient, handleSubmitCompany, handleSubmitPartner]


    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return(
        <ThemeProvider theme={theme}>

        <div className={'contact-form-container'}>

        <Card elevation={12} alignItems={'center'} sx={{ width: desktop ? '50vw' : "90vw", paddingRight: desktop ? 10 : 0, paddingLeft: desktop ? 10 : 0, paddingBottom: 10, paddingTop: 5}}>

            <Box sx={{ width: '100%', padding: 0 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} centered orientation={desktop ? 'horizontal' : 'vertical'}>
                        <Tab label="Cere ofertă" {...a11yProps(0)} />
                        <Tab label="Devino distribuitor" {...a11yProps(1)} />
                        <Tab label="Devino partener" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <h3 style={{margin: desktop ? 0 : 20, textAlign: 'center', paddingTop: desktop ? '3vh' : 0}}> Produsele noastre îți fac cu ochiul? Cere o ofertă! </h3>

                    <form ref={form} onSubmit={handleSubmitClient}>
                        <Grid container direction="row" justifyContent={desktop ? "flex-start": "center"} alignItems="center" spacing={desktop ? 2 : 0} sx={{width: '100%', padding: 0}} >

                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    id="name"
                                    label="Nume"
                                    name="name"
                                    margin="normal"
                                    onChange={(event)=> {setName(event.target.value)}}
                                    value={name}
                                    variant={'outlined'}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    id="email"
                                    label="Email"
                                    name="email"
                                    margin="normal"
                                    variant={'outlined'}
                                    onChange={(event)=> {setEmail(event.target.value)}}
                                    value={email}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    id="phone"
                                    label="Telefon"
                                    name="phone"
                                    margin="normal"
                                    variant={'outlined'}

                                    onChange={(event)=> {setPhone(event.target.value)}}
                                    value={phone}

                                />

                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    fullWidth
                                    required
                                    id="message"
                                    label="Mesaj"
                                    name="message"
                                    margin="normal"
                                    variant={'outlined'}
                                    multiline
                                    onChange={(event)=> {setMessage(event.target.value)}}
                                    value={message}
                                />

                            </Grid>
                        </Grid>
                    </form>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <h3 style={{margin: desktop ? 0 : 20, textAlign: 'center', paddingTop: desktop ? '3vh' : 0}}> Dorești o colaborare pe termen lung? Alege produsele ROKA pentru depozitul tău! </h3>
                    <form ref={formCompany} onSubmit={handleSubmitCompany}>
                        <Grid container direction="row" justifyContent={desktop ? "flex-start": "center"} alignItems="center" spacing={desktop ? 2 : 0} sx={{width: '100%', padding: 0}} >
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Denumire depozit"
                                    id="companyName"
                                    name="companyName"
                                    margin="normal"
                                    onChange={(event)=> {setCompanyName(event.target.value)}}
                                    value={companyName}
                                    variant={'outlined'}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    id="companyEmail"
                                    name="companyEmail"
                                    label="Email"
                                    margin="normal"
                                    variant={'outlined'}
                                    onChange={(event)=> {setCompanyEmail(event.target.value)}}
                                    value={companyEmail}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    id="companyPhone"
                                    name="companyPhone"
                                    label="Telefon"
                                    margin="normal"
                                    variant={'outlined'}
                                    onChange={(event)=> {setCompanyPhone(event.target.value)}}
                                    value={companyPhone}
                                />

                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    id="companyAddress"
                                    name="companyAddress"
                                    label="Adresa depozitului"
                                    margin="normal"
                                    variant={'outlined'}
                                    onChange={(event)=> {setCompanyAddress(event.target.value)}}
                                    value={companyAddress}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    fullWidth
                                    required
                                    margin="normal"
                                    label="Mesaj"
                                    id="companyMessage"
                                    name="companyMessage"
                                    variant={'outlined'}
                                    multiline
                                    onChange={(event)=> {setCompanyMessage(event.target.value)}}
                                    value={companyMessage}
                                />
                            </Grid>
                        </Grid>
                    </form>

                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <h3 style={{margin: desktop ? 0 : 20, textAlign: 'center', paddingTop: desktop ? '3vh' : 0}}> Dorești clienți mulțumiți? Alege produsele ROKA pentru lucrările companiei tale! </h3>
                    <form ref={formPartner} onSubmit={handleSubmitPartner}>
                        <Grid container direction="row" justifyContent={desktop ? "flex-start": "center"} alignItems="center" spacing={desktop ? 2 : 0} sx={{width: '100%', padding: 0}} >
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Denumire firmă"
                                    id="partnerName"
                                    name="partnerName"
                                    margin="normal"
                                    onChange={(event)=> {setPartnerName(event.target.value)}}
                                    value={partnerName}
                                    variant={'outlined'}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Email"
                                    id="partnerEmail"
                                    name="partnerEmail"
                                    margin="normal"
                                    variant={'outlined'}
                                    onChange={(event)=> {setPartnerEmail(event.target.value)}}
                                    value={partnerEmail}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Telefon"
                                    id="partnerPhone"
                                    name="partnerPhone"
                                    margin="normal"
                                    variant={'outlined'}
                                    onChange={(event)=> {setPartnerPhone(event.target.value)}}
                                    value={partnerPhone}
                                />

                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    id="partnerAddress"
                                    name="partnerAddress"
                                    label="Adresa firmei"
                                    margin="normal"
                                    variant={'outlined'}
                                    onChange={(event)=> {setPartnerAddress(event.target.value)}}
                                    value={partnerAddress}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Mesaj"
                                    margin="normal"
                                    id="partnerMessage"
                                    name="partnerMessage"
                                    variant={'outlined'}
                                    multiline
                                    onChange={(event)=> {setPartnerMessage(event.target.value)}}
                                    value={partnerMessage}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CustomTabPanel>
                <Grid item sm={3} style={{paddingTop: 10, paddingLeft: desktop ? 0 :50}}>

                    <CustomButton variant={'outlined'}
                                  type='submit'
                                  onClick={handleFunction[value]}
                    >
                        Trimite
                    </CustomButton>
                </Grid>

            </Box>



        </Card>
        </div>
        </ThemeProvider>
    );
}

export default ContactForm;