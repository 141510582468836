import React, { useState } from "react";
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Box from '@mui/material/Box';
import './styles.css'
import MenuIcon from "@mui/icons-material/Menu";
import {Link, useLocation} from "react-router-dom";

function DrawerComponent() {
    const currentPath = useLocation();

    const pages = ['Acasă', "Produse", "Distribuitori", "Contact"];

    const links = {
        'Acasă': '/acasa',
        "Produse" : '/produse',
        "Distribuitori": "/distribuitori",
        // "Parteneri": '/parteneri',
        "Contact" : '/contact'
    }
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <AppBar style={{position: "sticky", backgroundColor: 'white', justifyContent: 'flex-end', alignItems: 'end'}}>

            <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                anchor={'top'}
            >
                <Box sx={{ flexGrow: 1, display: { xs: 'grid', md: 'flex', justifyContent: 'flex-start', backgroundColor: 'white', paddingLeft: '10vw'} }}>

                    <List sx={{backgroundColor: 'white'}}>
                        {pages.map((page) => {
                            return (
                                links[page] !== currentPath.pathname
                                    ?

                                    <ListItem onClick={() => setOpenDrawer(false)}>
                                        <Link to={links[page]} style={{color: 'gray', textDecoration: 'none'}}>{page.toUpperCase()}</Link>
                                    </ListItem>
                                    :
                                    <ListItem onClick={() => setOpenDrawer(false)} sx={{backgroundColor: 'whitesmoke'}}>
                                        <Link to={links[page]} style={{color: 'black', textDecoration: 'none'}}>{page.toUpperCase()}</Link>
                                    </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Drawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)} style={{paddingRight: '20px'}}>
                <MenuIcon fontSize={'large'}/>
            </IconButton>
        </AppBar>
    );
}
export default DrawerComponent;