import {Chip, Grid, Icon} from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import './styles.css'

function Footer(){
    return (
        <div className={'footer'}>
            <Grid container sm={12} display={'row'} justifyContent={'center'} alignItems={'flex-start'} width={'100vw'} spacing={2}>
                <Grid item sm={3}>

                    <h2> PRODUSE DE CALITATE</h2>
                    <p> Ne mândrim cu produse de înaltă calitate.  Ofertăm boltari de gard modulo, boltari de zidărie, pavele și borduri de cea mai înaltă calitate. </p>

                </Grid>

                <Grid item sm={3}>
                    <h2>MISIUNEA NOASTRĂ</h2>
                    <p> Ne dedicăm satisfacției clienților și durabilității construcțiilor lor în timp.</p>
                </Grid>

                <Grid item sm={3}>
                    <h2>CONTACTEAZĂ-NE</h2>

                    <Box width="100%"/>

                    <Chip icon={<PhoneIcon style={{color: '#ff850c'}}/>} label={'0769 077 600'}
                          style={{
                              color: 'whitesmoke',
                              backgroundColor: '#676767',
                              paddingLeft: '4vw',
                              fontSize: '16px',

                          }}
                          onClick={()=>{
                              window.open('tel:+40769077600')
                          }}
                    />



                    <Chip icon={<PlaceIcon style={{color: '#ff850c'}}/>} label={'Târgu Jiu, Gorj, str. 23 August 115'}
                          style={{
                              color: 'whitesmoke',
                              backgroundColor: '#676767',
                              paddingLeft: '4vw',
                              fontSize: '16px',
                          }}

                    />

                </Grid>
                <Grid item sm={3}>
                    <h2>URMĂREȘTE-NE ȘI PE SOCIAL MEDIA</h2>
                    <div style={{paddingLeft: '5vw'}}>
                        <IconButton onClick={() => {window.open('https://www.facebook.com/RokaPavaje')}}>
                            <FacebookIcon/>
                        </IconButton>
                        <IconButton onClick={() => {window.open('https://www.facebook.com/RokaPavaje')}}>
                            <InstagramIcon/>
                        </IconButton>

                        <IconButton onClick={() => {window.open('https://www.facebook.com/RokaPavaje')}}>
                            <YouTubeIcon/>
                        </IconButton>


                    </div>

                </Grid>
            </Grid>
        </div>
    );
}

export default Footer