import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ToggleButton from "@mui/material/ToggleButton";
import {styled} from "@mui/material/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Divider from "@mui/material/Divider";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Product from "./Product";
import useMediaQuery from '@mui/material/useMediaQuery';
import {Fade} from "react-reveal";
import Footer from "../Footer";
import {firebase} from '../../firebase'
import {collection, getDocs} from "@firebase/firestore";


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {

        margin: theme.spacing(0),
        marginBottom: 0,
        paddingTop: 0,
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
            paddingTop: 10

        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
            paddingTop: 10
        },
    },
}));

function Products(props) {
    const desktop = useMediaQuery('(min-width:1200px)');
    const productsRef = collection(firebase, 'products')


    const theme = createTheme({
        palette: {
            primary: {
                main: '#ff850c'
            },
        },
    });


    const [value, setValue] = useState(0);

    const [currentCategory, setCurrentCategory] = useState('Toate');
    const categories = ['Toate', 'Bolțari de gard', 'Bolțari de zidărie', 'Bolțari de fundație', 'Borduri rezidențiale', "Borduri carosabile", 'Pavele rezidențiale', 'Pavele clasice']

    const getProducts = async () => {
        const data  = await getDocs(productsRef)
        let newProducts = []
        data.docs.map(doc => newProducts.push(doc.data()))
        setProducts(newProducts)
    }

    useEffect(() => {
        getProducts()
    }, [])

    const [products, setProducts] = useState([])

    const handleCategoryChange = (event, newCategory) => {
        if (newCategory) {
            setCurrentCategory(newCategory)
        }
    }


    return (
            desktop
                ?

            <ThemeProvider theme={theme}>

        <Grid container spacing={2}
              direction="column"
              justifyContent="space-around  "
              alignItems="center"
              auto
              padding={5}
        >

            <Grid container>
                {/*navigation system*/}

                <Grid item width={'15vw'} height={'100vh'} justifyContent={'center'}>
                    <Container component="main">
                        <StyledToggleButtonGroup
                            value={currentCategory}
                            exclusive
                            onChange={handleCategoryChange}
                            orientation="vertical"
                            size={'medium'}
                            color={'primary'}
                        >
                            {categories.map((element) => {
                                return <ToggleButton value={element} sx={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    fontSize: '13px'
                                }}>
                                    {element}

                                </ToggleButton>
                            })}
                        </StyledToggleButtonGroup>

                    </Container>
                </Grid>

                <Divider orientation="vertical" flexItem style={{borderWidth: '1px'}}/>

                {/*/navigation system*/}

                {/*content*/}
                <Grid item width={'78vw'}>
                    <Grid container m={0} sm={12}
                          sx={{alignItems: 'center', justifyContent: 'flex-start', paddingLeft: '3vw'}}>
                        {products.filter((element) => {
                            if (currentCategory === 'Toate')
                                return true
                            return element.category === currentCategory
                        }).map((element) => {
                            return <Grid item xs={4} sx={{maxWidth: '20vw', marginBottom: '10vh'}}>
                                <Product {...element}/> </Grid>
                        })}
                    </Grid>
                </Grid>


                {/*content*/}


            </Grid>
        </Grid>
                <div>
                    <Fade>
                        <Footer/>
                    </Fade>
                </div>

            </ThemeProvider>
                :
            <ThemeProvider theme={theme}>

                    <Grid container direction="column"
                          justifyContent="center"
                          alignItems="center"
                          auto
                          paddingTop={5}>
                        <Grid item width={'80vw'} height={'auto'} justifyContent={'center'} >
                                <StyledToggleButtonGroup
                                    value={currentCategory}
                                    exclusive
                                    onChange={handleCategoryChange}
                                    orientation="vertical"
                                    size={'small'}
                                    color={'primary'}
                                    sx={{width: '80vw'}}
                                    >
                                    {categories.map((element) => {
                                        return <ToggleButton value={element} sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontSize: '14px'
                                        }}>
                                            {element}

                                        </ToggleButton>
                                    })}
                                </StyledToggleButtonGroup>

                            <Divider orientation="horizontal" flexItem style={{borderWidth: '1px', marginTop: '2vh'}}/>
                        </Grid>

                        <Grid item width={'80vw'} justifyContent={'center'} alignItems={'center'} paddingTop={'5vh'}>
                            <Grid container
                                  alignItems="center"
                                  justify="center"
                                 >
                                {products.filter((element) => {
                                    if (currentCategory === 'Toate')
                                        return true
                                    return element.category === currentCategory
                                }).map((element) => {
                                    return <Grid item xs={12} sx={{width: '100%', marginBottom: '5vw'}}>
                                        <Product {...element}/> </Grid>
                                })}
                            </Grid>
                        </Grid>


                        {/*content*/}


                    </Grid>
                <div>
                    <Fade>
                        <Footer/>
                    </Fade>
                </div>


            </ThemeProvider>

    );
}

export default Products;