import {Grid} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

function Location(){
    const desktop = useMediaQuery('(min-width:768px)');

    return (
        desktop
        ?

        <Grid container width={'80vw'} sm={20} display={'row'} spacing={4} alignItems={'flex-start'} justifyContent={'space-around'} >

            <Grid item sm={6}>

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11281.3060090857!2d23.2742893!3d45.0182971!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4752753bc782b4c7%3A0xd9dbb92656d22d46!2sROKA!5e0!3m2!1sen!2sro!4v1695217789812!5m2!1sen!2sro"
                     allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Grid>
            <Grid item sm = {6}>
                <Grid container direction={'column'}>
                    <Grid item sm={12}>
                        <h2>Program de lucru</h2>
                        <p>
                            L-S: 08:00 - 17:00
                        </p>

                        <p>
                            Duminică & sărbători religioase/ legale: închis
                        </p>
                    </Grid>

                    <Grid item sm={12}>
                        <h2>Punct de lucru</h2>
                        <p>
                            jud. Gorj, Târgu Jiu
                        </p>

                        <p>
                            str. 23 August 115
                        </p>
                    </Grid>

                    <Grid item sm={12}>
                        <h2>Telefon</h2>
                        <p>
                            0769 077 600

                        </p>

                        <p>
                            0769 077 602
                        </p>
                        <p>

                            0769 077 607
                        </p>

                    </Grid>

                </Grid>
                </Grid>
            </Grid>


            :

            <>

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11281.3060090857!2d23.2742893!3d45.0182971!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4752753bc782b4c7%3A0xd9dbb92656d22d46!2sROKA!5e0!3m2!1sen!2sro!4v1695217789812!5m2!1sen!2sro"
                    allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    style={{width: '100vw'}}
                />

                <div className={'location-info'}>
                    <h2>Program de lucru</h2>
                    <p>
                        L-S: 08:00 - 17:00
                    </p>

                    <p>
                        Duminică & sărbători religioase/ legale: închis
                    </p>
                </div>

                <div className={'location-info'}>
                    <h2>Punct de lucru</h2>
                    <p>
                        jud. Gorj
                    </p>
                    <p>
                        Târgu Jiu
                    </p>
                    <p>
                        str. 23 August 115
                    </p>
                </div>

                <div className={'location-info'}>
                    <h2>Telefon</h2>
                    <p>
                        0769 077 600

                    </p>

                    <p>
                        0769 077 602
                    </p>
                    <p>
                        0769 077 607
                    </p>
                </div>
            </>
    )
}

export default Location;