import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import './styles.css'
import {ListItem, ListItemText, Drawer, List} from "@mui/material";
import DrawerComponent from "./DrawerComponent";
import logo from './images/logo.png'


const pages = ['Acasă', "Produse", "Distribuitori", "Contact"];
const links = {
    'Acasă': '/acasa',
    "Produse" : '/produse',
    "Distribuitori": "/distribuitori",
    // "Parteneri": '/parteneri',
    "Contact" : '/contact'
}

function Navbar() {
    const currentPath = useLocation();
    const desktop = useMediaQuery('(min-width:1200px)');

    useEffect(() => {
        window.scrollTo(0,0)
    }, [currentPath])

    return (

        desktop
            ?
            <AppBar position="sticky">
                <Container className={'navbar'}>
                    <Toolbar disableGutters>
                        <Link to={'acasa'} style={{ textDecoration: 'none' }}>
                            <img className={'logo'} src={logo} alt={'ROKA'}/>
                        </Link>
                        <Box sx={{ flexGrow: 1, display: { xs: 'grid', md: 'flex', justifyContent: 'flex-end', marginRight: '100px'} }}>
                            {pages.map((page) => {
                                    return (
                                        links[page] !== currentPath.pathname
                                            ?
                                            <Link to={links[page]} style={{textDecoration: 'none'}}>

                                                <Button
                                                    key={page}
                                                    className={'navbarButton'}
                                                >
                                                    {page}
                                                </Button>

                                            </Link>

                                            :
                                            <Link to={links[page]} style={{textDecoration: 'none'}}>

                                                <Button
                                                    key={page}
                                                    className={'navbarButton-currentPage'}
                                                >
                                                    {page}
                                                </Button>

                                            </Link>

                                    )
                                }
                            )}
                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>
            :
            <DrawerComponent/>

    );
}
export default Navbar;