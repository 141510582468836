import React from 'react';
import '../styles.css'
import {Grid, Card} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

function AboutSection ({imageLink, title, paragraphs, mirror, last=false}) {
    const desktop = useMediaQuery('(min-width:1200px)');

    return (
        desktop ?
            <div>
                {
                    mirror
                        ?
                        <div className={'centered-items'}>
                            <Card elevation={12} alignItems={'center'} sx={{ padding: 5, width: '90vw'}}>
                                <Grid container  display={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                                    <Grid item xs={9}>
                                        <div className={'about-section-image'}>
                                            <img src={imageLink}  alt=""/>
                                        </div>

                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className={'about-section-text'}  style={{backdropFilter: 'contrast(10%)', width: '200%', zIndex: '9', height: 'auto', marginLeft: '-100%', paddingBottom: '5vh'}}>
                                            <h2>{title}</h2>
                                            {paragraphs.map((text) => {
                                                return (<p> {text} </p>);
                                            })}

                                        </div>

                                    </Grid>

                                </Grid>
                            </Card>
                        </div>

                        :

                        <div className={'centered-items'}>
                            <Card elevation={12} alignItems={'center'} sx={{ padding: 5, width: '90vw'}}>
                                <Grid container  display={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                                    <Grid item xs={3}>
                                        <div className={'about-section-text'}  style={{backdropFilter: 'contrast(10%)', width: '200%', zIndex: '9', height: 'auto', paddingLeft: '5%', paddingRight:'5%', paddingBottom: '5vh'}}>
                                            <h2>{title}</h2>
                                            {paragraphs.map((text) => {
                                                return (<p> {text} </p>);
                                            })}

                                        </div>

                                    </Grid>
                                    <Grid item xs={9}>
                                        <div className={'about-section-image'}>
                                            <img src={imageLink}  alt=""/>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Card>
                        </div>
                }

            </div>

            :
            <div>
                <div className={'centered-items'}>
                    <div style={{ width: '100vw'}}>
                                <div className={'about-section-image'}>
                                    <img src={imageLink}  alt="" style={{width: '100vw'}}/>
                                </div>
                                <div className={'styled-text'}  style={{padding: 30}}>
                                    <h2>{title}</h2>
                                    {paragraphs.map((text) => {
                                        return (<p> {text} </p>);
                                    })}
                                </div>
                    </div>
                </div>
            </div>

    );
}

export default AboutSection;