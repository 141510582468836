import React, {useState} from "react";
import {GoogleMap, useJsApiLoader, MarkerF, InfoWindowF} from '@react-google-maps/api';
import { useGeolocation } from "@uidotdev/usehooks";
import {Grid, List, Select, FormControl, MenuItem, InputLabel} from '@mui/material'
import Box from "@mui/material/Box";
import {ListItem} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Fade} from "react-reveal";
import Footer from "../Footer";
import {collection, getDocs} from "@firebase/firestore";
import {useEffect} from "react";
import {firebase} from "../../firebase";

const containerStyle = {
    width: '60vw',
    height: '70vh',
};

const containerStyleNonDesktop = {
    width: '90vw',
    height: '70vh',
};
const center = {
    lat: 45.9432,
    lng: 24.9668,
};

const counties = [
    'Alba',
    'Arad',
    'Arges',
    'Bacau',
    'Bihor',
    'Bistrita-Nasaud',
    'Botosani',
    'Braila',
    'Brasov',
    'Buzau',
    'Calarasi',
    'Caras-Severin',
    'Cluj',
    'Constanta',
    'Covasna',
    'Dambovita',
    'Dolj',
    'Galati',
    'Giurgiu',
    'Gorj',
    'Harghita',
    'Hunedoara',
    'Ialomita',
    'Iasi',
    'Ilfov',
    'Maramures',
    'Mehedinti',
    'Mures',
    'Neamt',
    'Olt',
    'Prahova',
    'Salaj',
    'Satu Mare',
    'Sibiu',
    'Suceava',
    'Teleorman',
    'Timis',
    'Tulcea',
    'Valcea',
    'Vaslui',
    'Vrancea'
];

const MarkerDistributor = (props) => {
    const [clicked, setClicked] = useState(false)
    const showInfoWin = (e) =>{
        setClicked(!clicked)
    }

    let latitude = parseFloat(props.coordinates.lat)
    let longitude = parseFloat(props.coordinates.lng)

    let coords = {
        lat: latitude,
        lng: longitude
    }

    return (
        <MarkerF position={coords}
                 onClick={() => {setClicked(!clicked)}}>

            {(clicked)?(
                <InfoWindowF
                    onCloseClick={()=>setClicked(!clicked)}
                >
              <div className={'styled-text'}>
                  <h2> {props.name} </h2>
                  {props.address !== '' && <p> Adresa: {props.address}</p>}
                  {props.phone !== '' && <p> Telefon: {props.phone}</p>}
                  {props.email !== '' && <p> e-mail: {props.email} </p>
                  }              </div>
                </InfoWindowF>):('')}

        </MarkerF>
    )

}

export default function Distributors () {

    const distributorsRef = collection(firebase, 'distributors')

    const [distributors, setDistributors] = useState([]);

    const getDistributors = async () => {
        const data  = await getDocs(distributorsRef)
        let newDistributors = []
        data.docs.map(doc => newDistributors.push(doc.data()))
        setDistributors(newDistributors)
    }

    useEffect(() => {
        getDistributors()
    }, [])


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyApefPyB2K983rMqk8V_RVqd24CcAtu1pk"  //need to define your google api key
    })

    const [currentDistributor, setCurrentDistributor] = useState({
        lat: 0,
        lng: 0
    })

    const [currentCounty, setCurrentCounty] = useState('')

    const clientLocation = useGeolocation()

    const desktop = useMediaQuery('(min-width:1200px)');


    return (
        desktop
            ?
        <>{isLoaded ?
            <div>
                <Grid container sx={{
                    width: '90vw',
                    marginLeft: '5vw',
                    marginTop: '5vh',
                }}>
                    <Grid item>
                        <div style={{position:'relative'}}>
                            <div style={{position:'absolute', top: 10, left:10,backgroundColor:'#ff850c', padding:5, zIndex:999, color: 'white', borderRadius: '10px'}}>
                                <h1 style={{fontStyle: "Roboto", fontWeight: '300', fontSize: '30px'}}>DISTRIBUITORI</h1>
                            </div>
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={clientLocation.error ? center : {
                                    lat: clientLocation.latitude,
                                    lng: clientLocation.longitude
                                }}
                                zoom={clientLocation.error ? 7 : 9}
                            >


                                {distributors.map((location) => {
                                    return <MarkerDistributor {...location}/>
                                })}
                            </GoogleMap>
                        </div>
                    </Grid>

                    <Grid item>

                        <Box sx={{width: '25vw', height: '70vh', marginLeft: '3vw'}}>

                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-standard-label">Județ</InputLabel>
                                <Select

                                    value={currentCounty}
                                    onChange={(event) => {
                                        setCurrentCounty(event.target.value)
                                    }}
                                    label="Județ"
                                >
                                    <MenuItem value="">
                                        <em>Toată țara</em>
                                    </MenuItem>
                                    {counties.map((county) => {
                                        return <MenuItem value = {county}>{county}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                            <List style={{maxHeight: '100%', overflow: 'auto'}}>

                                {distributors.filter((distributor) => currentCounty === '' ? true : distributor.county.toLowerCase() === currentCounty.toLowerCase()).map((distributor) => {

                                    return (<ListItem className={'styled-text'} sx={{
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: 'whitesmoke',
                                        },
                                        display: 'block'


                                    }}>
                                        <h2> {distributor.name} </h2>
                                        {distributor.address !== '' && <p> Adresa: {distributor.address}</p>}
                                        {distributor.phone !== '' && <p> Telefon: {distributor.phone}</p>}
                                        {distributor.email !== '' && <p> e-mail: {distributor.email}</p>}

                                    </ListItem>)
                                })}
                            </List>
                        </Box>


                    </Grid>

                </Grid>
                <div style={{paddingTop: '10vh'}}>
                    <Fade>
                        <Footer/>
                    </Fade>
                </div>

            </div>

          : <></>}
        </>
        :
        <>
            {isLoaded ?
                <div>

                <Grid container sx={{
                    width: '90vw',
                    marginLeft: '5vw',
                    marginTop: '5vh',
                    direction: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}>
                    <Grid item xs={12}>
                        <div style={{position:'relative'}}>
                            <div style={{position:'absolute', top: 10, left:10, backgroundColor:'#ff850c', padding:5, zIndex:999, color: 'white', borderRadius: '10px'}}>
                                <h1 style={{fontStyle: "Roboto", fontWeight: '300', fontSize: '30px'}}>DISTRIBUITORI</h1>
                            </div>
                            <GoogleMap
                                mapContainerStyle={containerStyleNonDesktop}
                                center={clientLocation.error ? center : {
                                    lat: clientLocation.latitude,
                                    lng: clientLocation.longitude
                                }}
                                zoom={clientLocation.error ? 7 : 9}
                            >


                                {distributors.map((location) => {
                                    return <MarkerDistributor {...location}/>
                                })}
                            </GoogleMap>
                        </div>
                    </Grid>

                    <Grid item xs={12}>

                        <Box sx={{width: '90vw', height: '70vh'}}>

                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-standard-label">Județ</InputLabel>
                                <Select
                                    value={currentCounty}
                                    onChange={(event) => {
                                        setCurrentCounty(event.target.value)
                                    }}
                                    label="Județ"
                                >
                                    <MenuItem value="">
                                        <em>Toată țara</em>
                                    </MenuItem>
                                    {counties.map((county) => {
                                        return <MenuItem value = {county}>{county}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                            <List style={{maxHeight: '100%', overflow: 'auto'}}>

                                {distributors.filter((distributor) => currentCounty === '' ? true : distributor.county.toLowerCase() === currentCounty.toLowerCase()).map((distributor) => {

                                    return (<ListItem className={'styled-text'} sx={{
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: 'whitesmoke',
                                        },
                                        display: 'block'


                                    }}>
                                        <h2> {distributor.name} </h2>
                                        {distributor.address !== '' && <p> Adresa: {distributor.address}</p>}
                                        {distributor.phone !== '' && <p> Telefon: {distributor.phone}</p>}
                                        {distributor.email !== '' && <p> e-mail: {distributor.email}</p>}

                                    </ListItem>)
                                })}
                            </List>
                        </Box>


                    </Grid>

                </Grid>
                <div style={{paddingTop: '10vh'}}>
                    <Fade>
                        <Footer/>
                    </Fade>
                </div>

                </div>

                : <></>}
        </>)

}