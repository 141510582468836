import React from 'react';
import {Card, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {useState} from "react";

export default function Authenticate({setAuth}) {
    const [password, setPassword] = useState('');

    const authKey = '55997874762110915408124405022435';

    const handleAuth = () => {
        if (password === authKey)
            setAuth(true);
    }

    return (
        <>
        <div style={{
            display: 'grid',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',

        }}>


            <Card sx={{  width: '30vw',
                height: '30vh',
                justifyContent: 'center',
                alignItems: 'center'}}
                  elevation={12}
            >

                <div className={'styled-text'} style={{paddingLeft: '5vw'}}>
                    <h1>Autentificare admin</h1>
                </div>

                <Stack direction="column" alignItems="center" spacing={2} paddingTop={"5vh"}>
                    <TextField variant={"outlined"}
                               label={'Parola'}
                               value={password}
                               onChange={(event) => {
                                   setPassword(event.target.value)
                               }}
                    />
                    <Button
                        variant={'outlined'}
                        onClick={handleAuth}
                    >
                        Autentificare
                    </Button>
                </Stack>

            </Card>

        </div>
        </>
    );
}

