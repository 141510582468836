import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from '@mui/material/Slide'
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import '../styles.css'
import Grid from '@mui/material/Grid'
import Divider from "@mui/material/Divider";
import useMediaQuery from '@mui/material/useMediaQuery';
import SimpleImageSlider from "react-simple-image-slider";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Product(props) {
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const desktop = useMediaQuery('(min-width:1200px)');

    const dimension = desktop ? '23vw' : '80vw'


    const {name, description, images, dimensions, technicalSpecifications, advantages, colors} = props

    return (
        desktop
        ?
        <div>
            <Card sx={{maxWidth: dimension, minHeight: '400px',
                transition: "transform 0.15s ease-in-out",
                ':hover': {
                    boxShadow: 20,
                    transform: "scale3d(1.05, 1.05, 1)"
                },
                borderRadius: '10%'
            }} onClick={handleClickOpen} elevation={12}>
                <Stack direction="column" alignItems="center" spacing={2} marginTop={"20px"}>
                    {images && <img src={images[0]} style={{
                        height: '250px', aspectRatio: '1', objectFit: 'cover', overflow: 'hidden',
                        ':hover': {
                            borderRadius: '20%'
                        },
                    }}/>}
                    <h2 style={{ maxWidth: '90%' ,textAlign: 'center'}}>{name.toUpperCase()}</h2>
                </Stack>
            </Card>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{ sx: { borderRadius: "30px", minWidth: '70vw' } }}
                sx={{
                    backdropFilter: "blur(5px) sepia(10%)",

                }}

            >
                <DialogContent>
                    <div className={'item-description'}>
                        <div  style={{paddingLeft: '20%'}}>
                            <h1 style={{fontSize: '50px', fontWeight: '300'}}>
                                {name.toUpperCase()}
                            </h1>
                        </div>


                        <Grid container direction={'row'} alignItems="flex-start" justifyContent='space-between' spacing={2} marginTop={"5vh"} paddingLeft={'3vw'} paddingRight={'3vw'} sx={{width: '100%'}}>

                            <Grid item xs={8}>
                                <div className={'styled-text'}>

                                    <h2>Descriere</h2>

                                    <p>{description}</p>

                                    <Divider/>


                                    {advantages && <>
                                        <h2>Avantaje</h2>
                                        {advantages.map((advantage) => {
                                            return <p>{advantage}</p>
                                        })}
                                    <Divider/>
                                    </>
                                    }
                                    {/*{advantages}*/}


                                </div>
                            </Grid>

                            <Grid item xs={3.5}>
                                {images && <div>
                                    <SimpleImageSlider
                                        width={250}
                                        height={250}
                                        images={images}
                                        showNavs={true}
                                        autoPlay={true}
                                        style={{position: 'relative'}}
                                    />
                                </div>}

                                <div className={'styled-text'}>
                                    { technicalSpecifications && <>
                                        <h2>Specificații tehnice</h2>
                                        {technicalSpecifications.map((specification) => {
                                            return <p>{specification}</p>
                                        })}
                                        <Divider/>
                                    </>
                                    }
                                    <h2>Dimensiuni </h2>
                                    <div style={{fontSize: '16px', fontWeight: '300', marginBottom: '7px'}}> {dimensions.length} x {dimensions.width} x {dimensions.height} cm</div>

                                    <Divider/>

                                    <h2>Culori</h2>
                                    <div style={{display: 'flex', paddingTop: '7px', paddingLeft: '5px'}}>
                                        {colors.map((color) => {
                                            return <div
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    borderRadius: '50%',
                                                    backgroundColor: `#${color}`,
                                                    marginRight: '5px'
                                                }}
                                            > </div>
                                        })}
                                    </div>
                                </div>
                            </Grid>


                      </Grid>
                    </div>

                </DialogContent>

            </Dialog>
        </div>
    :
            <div>
                <Card sx={{maxWidth: dimension, minHeight: '400px',
                    transition: "transform 0.15s ease-in-out",
                    ':hover': {
                        boxShadow: 20,
                        transform: "scale3d(1.05, 1.05, 1)"
                    },
                    borderRadius: '10%'
                }} onClick={handleClickOpen} elevation={12}>
                    <Stack direction="column" alignItems="center" spacing={2} marginTop={"20px"}>
                        {images && <img src={images[0]} style={{
                            height: '250px', aspectRatio: '1', objectFit: 'cover', overflow: 'hidden',
                            ':hover': {
                                borderRadius: '20%'
                            },
                        }}/>}
                        <h2 style={{ maxWidth: '90%' ,textAlign: 'center'}}>{name.toUpperCase()}</h2>
                    </Stack>
                </Card>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{ sx: { borderRadius: "30px", minWidth: '70vw' } }}
                    sx={{
                        backdropFilter: "blur(5px) sepia(10%)",
                    }}

                >
                    <DialogContent>
                        <div className={'item-description'}>
                            <div  style={{paddingLeft: '10vw'}}>

                                <h1 style={{fontSize: '32px', fontWeight: '300'}}>
                                    {name.toUpperCase()}
                                </h1>
                            </div>


                            <Grid container direction={'row'} alignItems="flex-start" justifyContent='space-between' spacing={2} marginTop={"5vh"} sx={{width: '100%'}}>

                                <Grid item xs={12}>
                                    {images && <div>
                                        <SimpleImageSlider
                                            width={250}
                                            height={250}
                                            images={images}
                                            showNavs={true}
                                            autoPlay={true}
                                            style={{position: 'relative'}}
                                        />
                                    </div>
                                    }
                                </Grid>


                                <Grid item xs={12}>
                                    <div className={'styled-text'}>
                                        <h2>Descriere</h2>

                                        <p>{description}</p>

                                        <Divider/>

                                        <h2>Dimensiuni </h2>
                                        <div style={{fontSize: '16px', fontWeight: '300', marginBottom: '7px'}}> {dimensions.length} x {dimensions.width} x {dimensions.height} cm</div>

                                        <Divider/>

                                        {technicalSpecifications && <>
                                            <h2>Specificații tehnice</h2>
                                            {technicalSpecifications.map((specification) => {
                                                return <p>{specification}</p>
                                            })}
                                            <Divider/>
                                        </>
                                        }

                                        <h2>Culori</h2>
                                        <div style={{display: 'flex', paddingTop: '7px', paddingLeft: '5px',
                                            paddingBottom: '20px'
                                        }}>
                                            {colors.map((color) => {
                                                return <div
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        borderRadius: '50%',
                                                        backgroundColor: `#${color}`,
                                                        marginRight: '5px'

                                                    }}
                                                > </div>
                                            })}
                                        </div>
                                        <Divider/>
                                        { advantages && <>
                                        <h2>Avantaje</h2>
                                        {advantages.map((advantage) => {
                                            return <p>{advantage}</p>
                                        })}
                                        <Divider/>
                                        </>}




                                    </div>
                                </Grid>



                            </Grid>
                        </div>

                    </DialogContent>

                </Dialog>
            </div>);
}

export default Product;