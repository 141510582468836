import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import {useState} from "react";
import boltariModulo from './images/boltariModulo.jpeg'
import pavele from './images/pavele.jpeg'
import borduri from './images/borduri.jpeg'
import boltari from './images/boltari.jpeg'
//
// const boltariModulo = "https://piatradepo.ro/wp-content/uploads/2022/05/Piatradepo-Modulo-Dark-2-1024x683.jpg"
// const pavele = "https://northernpinesgreenery.com/wp-content/uploads/2018/02/Rinn_Medino.jpg";
// const borduri = "https://elis.ro/public/upload/images/product/2022/11/cache/636e67e68b8423.45064720.1200x800-adaptive.jpg";
// const boltari = "https://www.reformex.ro/wp-content/uploads/2020/07/zidarie-casa-din-boltari-1024x541.jpg";
//

export default function ImageSlider() {

    const [currentSlide, setCurrentSlide] = useState(1)
    const slideTitle = ['BOLȚARI DE GARD ROKA', 'PAVELE ROKA', 'BORDURI REZIDENȚIALE', 'bolțari de zidărie']
    const slideDescription = [' MODULO BLOCK', 'FANTASIA TRIO SMART', 'ROKA B5', 'ROKA EZ2']
    return (
        <HeroSlider
            height={"90vh"}
            autoplay
            controller={{
                initialSlide: 1,
                slidingDuration: 1000,
                slidingDelay: 200,
                onSliding: (nextSlide) =>
                    setCurrentSlide(nextSlide)
            }}
        >
            <Overlay>
                <div className={'image-slider-wrapper'}>
                    <div className={'image-slider-title'}>
                        {slideTitle[currentSlide-1]}
                    </div>
                    <div className={'image-slider-subtitle'}>
                        {slideDescription[currentSlide-1]}
                    </div>
                </div>
            </Overlay>
            <Slide
                shouldRenderMask
                label="Garduri modulo"
                background={{
                    backgroundImageSrc: boltariModulo,
                }}
            />
            <Slide
                shouldRenderMask
                label="Pavele"
                background={{
                    backgroundImageSrc: pavele
                }}
            />

            <Slide
                shouldRenderMask
                label="Borduri"
                background={{
                    backgroundImageSrc: borduri
                }}
            />
            <Slide
                shouldRenderMask
                label="Boltari"
                background={{
                    backgroundImageSrc: boltari,
                }}
            />



            <MenuNav />
        </HeroSlider>
    );
}
