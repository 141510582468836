import {Fade, Slide} from 'react-reveal'
import Footer from '../Footer'
import '../styles.css'
import ImageSlider from "./ImageSlider";
import AboutSection from "./AboutSection";
import gardenPavers from './images/garden-pavers.jpeg'
import constructionTeam from './images/construction-team.jpeg'
import ContactForm from "../contact/ContactForm";
import boltariModulo from './images/boltariModulo.jpeg'
import pavele from './images/pavele.jpeg'
import borduri from './images/borduri.jpeg'
import boltari from './images/boltari.jpeg'


function Home() {
    return(

        <>
            <Fade duration={1500} left distance="0%">
                <ImageSlider/>
            </Fade>

            <Fade duration={1500} left distance="0%">
                <div className={'separator'}/>
            </Fade>

                <div>
                    <Slide duration={1000} left distance="0%">
                        <AboutSection imageLink={boltariModulo} title={'ROKA® MODULO BLOCK'} paragraphs={['Bolțarii de gard Roka® Modulo Block sunt produse inovatoare care scurtează cu până la 90% timpul necesar construirii unui gard și elimină erorile cauzate de factorul uman și greselile de montaj.', 'Se reduce cu 75% numărul operatiunilor de manopera necesare ridicării unui gard cu bolțari clasici, nefinisați prin eliminarea operațiunilor de cofrare, tencuire, amorsare, rostuire, curățare și impermeabilizare. Reducerea numărului de operatiuni la manopera conduce la o scădere cu până la 85% a costului total al manoperei necesare construirii unui gard. Bolțarii de gard Roka® Modulo Block sunt un produs 3D, de tip modular, cu toate părțile finisate, portanti si sunt realizati din beton compozit cu o rezistență sporită la șocuri mecanice și cicluri de îngheț-dezgheț.', 'Pot fi utilizati atat la construirea gardului cat si la amenajarea teraselor sau a spatiilor din imprejurul casei dvs. Bolțarii de gard Roka® Modulo Block sunt foarte ușor de manipulat, sunt proiectați pentru armături de fier cu diametrul de 8-10 mm iar montarea lor nu permite aparitia scurgerilor de beton de armare.']}/>
                    </Slide>

                    <Slide duration={1000} right distance="0%">
                        <AboutSection mirror={true} imageLink={boltari} title={'BOLTARI DE ZIDARIE EZ 2'} paragraphs={['Aceștia sunt soluția sigură, rapidă și economică de realizare a unei lucrări de zidărie, indiferent că este vorba de casă, anexele acesteia sau clădiri industriale. Rezistența deosebită, chiar și în medii umede, îi recomandă inclusiv în construcția pivnițelor și a cramelor. Bolțarii pot fi utilizați oriunde se impune construirea unui zid, inclusiv la construirea unui gard.', 'Pot fi utilizați atât la construirea zidurilor exterioare, cât și la ridicarea celor interioare. In funcție de destinația construcției, se va folosi tipul de bolțar cu lățimea corespunzatoare. Prezintă avantajul că sunt de mărime mare, deci se lucrează cu spor. Un alt avantaj este dimensiunea exactă, cu implicații pozitive asupra timpului de lucru, a costului manoperei și a calitații lucrării. În plus, au o rezistența superioară materialelor similare in mediile expuse la umiditate și intemperii (garduri, adăposturi, cămine subterane, piscine, fose septice, etc).']}/>
                    </Slide>
                </div>
                <div style={{backgroundColor: 'lightgray'}}>

                    <div className={'separator'}/>

                    <Slide duration={1000} left distance="0%">
                        <AboutSection imageLink={gardenPavers} title={'Producător de prefabricate din beton de calitate'} paragraphs={['Suntem o companie tânără, în plină dezvoltare, care dorim să asigurăm clienților noștri o gamă diversificată de produse, prefabricate din beton, de calitate, la prețuri corecte.', 'Vă oferim o gamă de produse prefabricate din beton diversificată: bolțari de gard, bolțari de zidărie, bolțari de cofrare, borduri rezidențiale sau industriale, pavele simple, colorate sau cu mix de culori.']}/>
                    </Slide>
                    <Slide duration={1000} right distance="0%">
                        <AboutSection mirror={true} imageLink={constructionTeam} title={'Parteneri la nivel național'} paragraphs={['Oferim soluții de montaj asigurate de partenerii noștri la nivel național!', 'Dacă ești constructor și ești interesat de montarea produselor noastre, de colaborare și promovare reciprocă, sau dacă ești producător de gard de fier sau lemn și ești interesat de colaborare și promovare reciprocă, noi ne dorim să devenim parteneri!']}/>
                    </Slide>
                    <Fade duration={1500} left distance="0%">
                        <div className={'separator'}/>
                    </Fade>

                </div>


                <Fade duration={1500} left distance="0%">
                    <ContactForm/>
                </Fade>

            <Fade duration={1500} left distance="0%">
                <Footer/>
            </Fade>

        </>

    )
}

export default Home