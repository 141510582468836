import { initializeApp } from "firebase/app";
import {getFirestore} from '@firebase/firestore'
import { getStorage } from "firebase/storage";

const firebaseConfig = {

    apiKey: "AIzaSyBAWyzhMz9qxcS_tIoXABoJT1slAkB1FS4",
    authDomain: "roka-699fd.firebaseapp.com",
    projectId: "roka-699fd",
    storageBucket: "roka-699fd.appspot.com",
    messagingSenderId: "156791454032",
    appId: "1:156791454032:web:3addb00599e58edabf1455"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebase = getFirestore(app);
export const storage = getStorage(app);
