import React from 'react';
import {useState} from "react";
import {createTheme, styled} from "@mui/material/styles";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card'
import NewProduct from "./NewProduct";
import NewDistributor from "./NewDistributor";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',

    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E0E3E7',
            backgroundColor: 'whitesmoke'
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
        },
    },
});

const CustomButton = styled(Button)(() => ({
    color: '#ff850c',
    borderColor: '#ff850c',
    backgroundColor:  'white',
    '&:hover': {
        backgroundColor:  '#ffab59',
        borderColor: '#ff850c',
    },
}));

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#ff850c'
        },
    },
});

function Authenticated(props) {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box alignItems={'center'} sx={{ padding: 10, width: '100vw'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} centered>
                        <Tab label="Adaugă produs nou" {...a11yProps(0)} />
                        <Tab label="Adaugă distribuitor" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <NewProduct/>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    <NewDistributor/>
                </CustomTabPanel>
        </Box>
                    );
}

export default Authenticated;